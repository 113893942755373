<template>
  <div v-if="data">
    <img class="icon mr-2" :src="messageIcon(data.Location)" /> {{data.Subject}}
    <br />
    <small>{{getTimeString(data.StartTime)}} -  {{getTimeString(data.EndTime)}}
    <br />
    {{ $t('MESSAGES.TYPE.' + data.Location) }}</small>
  </div>
</template>
<script>
export default {
  data() {
    return {
      days: 0
    }
  },
  methods: {
    messageIcon(message_type)
    {
      return "/img/message_type/" + message_type + ".png";
    },
    getTimeString(data){
      return this.getFormattedTime(data.toISOString());
    },
    getFormattedTime(data)
    {
      return this.FormatDateTime(data);
    }
  },
  computed: {
  },
  mounted: function() {
    /*let startTime = new Date(this.data.StartTime);
    let endTime = new Date(this.data.EndTime);
    let diff = endTime.getTime() - startTime.getTime();
    this.days = Math.round(diff / (1000 * 3600 * 24));*/
  },
};
</script>
<style>
  </style>
