var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data
    ? _c("div", [
        _c("img", {
          staticClass: "icon mr-2",
          attrs: { src: _vm.messageIcon(_vm.data.Location) },
        }),
        _vm._v(" " + _vm._s(_vm.data.Subject) + "\n  "),
        _c("br"),
        _c("small", [
          _vm._v(
            _vm._s(_vm.getTimeString(_vm.data.StartTime)) +
              " -  " +
              _vm._s(_vm.getTimeString(_vm.data.EndTime)) +
              "\n  "
          ),
          _c("br"),
          _vm._v("\n  " + _vm._s(_vm.$t("MESSAGES.TYPE." + _vm.data.Location))),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }